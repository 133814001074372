<template>
  <div>
    <load-profile></load-profile>
    <section class="invoice-preview-wrapper">
      <b-row v-if="data.id" class="invoice-preview">
        <b-col cols="12" md="8" xl="9" class="pr-md-0">
          <b-card no-body class="invoice-preview-card">
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <div>
                  <div class="logo-wrapper">
                    <h3 class="text-primary invoice-logo ml-0">
                      {{ $t("breadcrumb.salesInvoice") }}
                    </h3>
                  </div>
                  <p class="card-text mb-25">
                    {{ data.billToCustomerName }}
                  </p>
                  <p class="card-text mb-0">
                    {{ data.billToAddress }}
                  </p>
                </div>

                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    <span class="invoice-number">#{{ data.no }}</span>
                  </h4>
                </div>
              </div>
            </b-card-body>

            <hr class="invoice-spacing" />

            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <b-col cols="12" xl="6" class="p-0">
                  <h6>{{ $t("field.invoiceTo") }}:</h6>
                  <h6 class="mb-25">
                    {{ data.customerName }}
                  </h6>
                  <p class="card-text mb-25">
                    {{ data.customerCode }}
                  </p>
                  <p class="card-text mb-25">
                    {{ data.customerAddress }}
                  </p>
                  <p class="card-text mb-25">
                    {{ data.customerContactNo }}
                  </p>

                  <h6 class="mt-2">{{ $t("field.shipment") }}:</h6>
                  <h6 class="mb-25">
                    {{ data.shipToContact }}
                  </h6>
                  <p class="card-text mb-25">
                    {{ data.shipToAddress }}
                  </p>
                </b-col>

                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div>
                    <h6>{{ $t("field.information") }}:</h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">{{ $t("field.orderNo") }} :</td>
                          <td>
                            {{ data.orderNo }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">{{ $t("field.date") }} :</td>
                          <td>
                            <span class="font-weight-bold">
                              {{ data.postingDate | formatDate("LL") }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">{{ $t("field.shipmentDate") }} :</td>
                          <td>
                            <span class="font-weight-bold">
                              {{ data.shipmentDate | formatDate("LL") }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            {{ $t("field.paymentMethod") }} :
                          </td>
                          <td>
                            <span class="font-weight-bold">
                              {{
                                data.paymentMethodCode
                                  ? data.paymentMethodCode
                                  : "-"
                              }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">{{ $t("field.locationCode") }} :</td>
                          <td>
                            {{ data.locationCode }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            {{ $t("field.orderReference") }} :
                          </td>
                          <td>
                            {{ data.externalDocumentNo }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <div class="order-summary">
              <n-table
                class="px-2 order-summary-table"
                ref="table"
                :fields="fields"
                :items="displayItems"
              >
                <template #cell(productName)="data">
                  {{ data.description }}
                  <small class="d-block text-primary">
                    {{ data.item.no }}
                  </small>
                  <small
                    v-for="(description, i) in getLineDiscountDescription(
                      data.item
                    )"
                    :key="`description-${i}`"
                    class="d-block"
                  >
                    {{ data.description }}
                  </small>
                </template>
                <template #cell(quantity)="data">
                  {{ data.item.quantity }} {{ data.item.uomCode }}
                </template>
                <template #cell(lineDiscount)="data">
                  <span class="text-danger">
                    {{ getLineDiscount(data.item) | currency }}
                  </span>
                </template>
                <template #cell(lineAmount)="data">
                  <span
                    :class="{
                      'text-danger': getLineTotalAfterDiscount(data.item) < 0,
                    }"
                  >
                    {{ getLineTotalAfterDiscount(data.item) | currency }}
                  </span>
                </template>
              </n-table>

              <b-card-body class="invoice-padding">
                <b-row>
                  <b-col cols="12" class="d-flex justify-content-end">
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t("field.total") }}:
                        </p>
                        <p class="invoice-total-amount">
                          {{ data.amount | currency }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t("field.vat") }}:
                        </p>
                        <p class="invoice-total-amount">
                          {{ data.totalTaxAmount | currency }}
                        </p>
                      </div>
                      <hr class="my-50" />
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t("field.totalWithVat") }}:
                        </p>
                        <p class="invoice-total-amount text-primary">
                          {{ data.amountIncludingVat | currency }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </div>
          </b-card>
        </b-col>

        <b-col cols="12" md="4" xl="3" class="invoice-actions">
          <b-card>
            <download-pdf-button
              :repository="SalesInvoiceRepository"
              :id="data.id"
              :file-name="$t('breadcrumb.invoice') + ' - ' + data.no"
              variant="success"
              block
            >
              <i class="las la-file-invoice-dollar"></i>
              {{ $t("button.downloadInvoice") }}
            </download-pdf-button>

            <n-button-loading
              class="mt-1"
              variant="danger"
              block
              :loading="loading"
              @click="returnOrder"
            >
              <i class="las la-undo pr-25"></i>
              {{ $t("button.returnOrder") }}
            </n-button-loading>

            <hr class="my-1" v-if="data.orderId" />

            <b-button
              v-if="data.orderId"
              variant="primary"
              block
              :to="{
                name: 'view-order',
                params: {
                  id: data.orderId,
                },
              }"
            >
              {{ $t("button.viewOrder") }}
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BBadge,
  BTr,
  BTh,
  BButton,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import NButtonLoading from "@/components/NButtonLoading";
import NTable from "@/components/NTable";
import tableFields from "./viewTableFields";
import DownloadPdfButton from "@/components/DownloadPdfButton";

const SalesInvoiceRepository = Repository.get("salesInvoice");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BBadge,
    BTr,
    BTh,
    BButton,

    NTable,
    NButtonLoading,
    DownloadPdfButton,
  },
  data() {
    return {
      loading: false,
      data: {},
      items: [],
      displayItems: [],
      SalesInvoiceRepository,
    };
  },
  created() {
    this.show();
  },
  methods: {
    getLineTotalAfterDiscount(item) {
      if (item.lineAmount == 0 && item.unitPrice > 0) {
        return this.getLineDiscount(item) + item.quantity * item.unitPrice;
      }
      return item.lineAmount + this.getLineDiscount(item);
    },
    getLineDiscount(item) {
      let totalDiscount = 0;
      if (item.lineAmount == 0 && item.unitPrice > 0) {
        totalDiscount = item.lineDiscount * -1;
      }
      this.items.forEach((element) => {
        if (element.attachedToLineNo == item.lineNo) {
          totalDiscount += element.lineAmount;
        }
      });
      return totalDiscount;
    },
    getLineDiscountDescription(item) {
      let descriptions = [];
      this.items.forEach((element) => {
        if (element.attachedToLineNo == item.lineNo) {
          descriptions.push(element);
        }
      });
      return descriptions;
    },
    show() {
      this.loading = true;
      SalesInvoiceRepository.show(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = { ...data };
            this.items = [...data.lines];
            const displayItems = [];
            this.items.forEach((element) => {
              if (element.attachedToLineNo == 0) {
                displayItems.push(element);
              }
            });
            this.displayItems = [...displayItems];
          }
        })
        .catch()
        .then(() => {
          this.loading = false;
        });
    },
    returnOrder() {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.returnOrder"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("button.returnOrder"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            SalesInvoiceRepository.returnOrder(this.$route.params.id)
              .then((response) => {
                this.$router.push({
                  name: "create-sales-return-order",
                });
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          }
        });
    },
  },
  setup() {
    const fields = [...tableFields];

    const resource = "agent-sales-invoice";
    const route = "sales-invoice";

    return {
      fields,
      resource,
      route,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
